import * as React from 'react';
import { Box, Heading, Text, Flex } from '@chakra-ui/react';
import CTAButton from '../components/CTAButton';
import SeoComponent from '../components/SeoComponent';

function NotFoundPage(props) {
  return (
    <>
      <SeoComponent
        title={'Wana Brands : Page Not Found'} 
      />

      <Flex
        w="100%"
        h={{base:'480px', md:'54vw', lg:'54vw', xl:'761px'}}
        position="relative"
        justifyContent="center"
      >
        {/* GRAY SVG */}
        <Box
          w="100%"
          h={{base:'460px', md:'52vw', lg:'52vw', xl:'741px'}}
          position="absolute"
          top="0"
          left="0"
          style={{zIndex:0}}
        >
          <svg 
            viewBox="0 0 500 149" 
            preserveAspectRatio="none" 
            style={{width:'100%', height:'100%', zIndex:0}}
          >
            <path
              fill="#F1F0FA"
              fillOpacity='1'
              d="M-0.84,142.61 C216.98,113.98 385.15,180.11 501.97,123.86 L500.00,0.00 L0.00,0.00 Z"
            >
            </path>
          </svg> 
        </Box>

        {/* PAGE CONTENT */}
        <Flex
          w={{base:'95%', ms:'100%', md:'87vw', mds:'87vw', lg:'84vw', xl:'1200px'}} 
          h="auto"
          position="absolute"
          top={{base:'20vw', ms:'18vw', msx:'17vw', md:'14.38vw', xl:'207px'}}
          zIndex="1"
          direction="column"
          alignItems="center"
        >
          <Heading
            maxW={{base:'451px'}}
            color="primary"
            fontFamily="Outfit"
            fontSize={{base:'50px'}}
            fontWeight={{base:'900'}}
            lineHeight={{base:'45px'}}
            letterSpacing={{base:'-0.38px'}}
            textTransform="uppercase"
            textAlign="center"
            mb={{base:'18px'}}
          >
            {'Page Not Found'}
          </Heading>

          <Text
            maxW={{base:'424px'}}
            color="primary"
            fontSize={{base:'15px'}}
            fontWeight={{base:'600'}}
            lineHeight={{base:'30px'}}
            letterSpacing={{base:'0'}}
            textAlign="center"
            pb={{base:'36px'}}
            mb={{base:'18px'}}
          >
            {'Oops! This may be an old link or a mistake. Let’s get you back home to find what you are looking for.'}
          </Text>

          {/* BUTTON */}
          <CTAButton
            href={'/'}
            width={{base:'220px', ssm:'260px', md:'316px'}}
            height={{base:'40px', lg:'48px'}}
            fontSize={{base:'10px', md:'13px'}}
            fontWeight='900'
            bgColor={'greenMantis'}
            color={'white'}
            _hover={{ bg: '#311D56', borderColor: '#311D56' }}
            ctaText={'Go To Home Page'}
          />
        </Flex>
      </Flex>
    </>
  );
}

export default NotFoundPage;
